import * as React from "react"
import Container from "../components/Container"
import Metadata from "../components/Metadata"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"

export const Head = () => <Metadata title="404"/>

const Page404 = ({data, location}) => {
  return (
    <Site location={location} slug="404">
      <Container>
        <h2>404: Not Found</h2>
        <p>You just hit a route that doesn&apos;t exist.</p>
        <SiteLink href="/">Go Home</SiteLink>
      </Container>
    </Site>
  )
}

export default Page404
